import React from "react";
import { Tooltip } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";

const TooltipHint = ({ className, children, placement = "top", triggerId, innerClassName, ButtonComponent, hideArrow = false }) => {
  const [isOpen, setOpen] = React.useState(false);
  const toggle = () => {
    setOpen(!isOpen);
  };

  return (
    <span className={className}>
      <button
        className="btn-invisible"
        type="button"
        id={triggerId}
        tabIndex="-1"
      >
        {ButtonComponent
          ? <ButtonComponent />
          : <span className="icon icon--solid icon--circle-question text-grey cursor-pointer">{" "}</span>
        }
      </button>
      <Tooltip
        innerClassName={innerClassName}
        hideArrow={hideArrow}
        target={triggerId}
        placement={placement}
        isOpen={isOpen}
        toggle={toggle}
      >
        {children}
      </Tooltip>
    </span>
  );
};

const Separator = ({ className }) => (
  <div
    className={`${className} separator separator--bottom separator--solid separator--grey-d"`}
  />
);

const BuyBackGuarantee = ({ messages, green }) => (
  <div className="mb-10 d-flex align-items-center justify-content-between">
    <p className={classNames("mb-0", { "text-success": green })}>
      <span className="icon icon--regular icon--shield me-10" />
      {messages["controls.buy_back_guarantee"]}
    </p>
    <TooltipHint className="ms-10" triggerId="id_buyback_hint">
      {messages["controls.buy_back_guarantee.hint"]}
    </TooltipHint>
  </div>
);

const ReviewStars = ({ level }) => {
  const stars = [false, false, false, false, false];
  for (let i = 0; i < level; i += 1) {
    stars[i] = true;
  }

  return (
    <span className="text-sm d-inline-flex align-items-center">
      {stars.map((q, index) => (
        <span
          key={index}
          className={`icon icon--solid icon--star ${
            q ? "text-primary" : "text-grey"
          }`}
        >
          {" "}
        </span>
      ))}
    </span>
  );
};

const ReviewsWidget = ({ reviewStats, withLink }) => {
  const { messages } = useIntl();
  return (
    <div className="ratings d-flex align-items-center">
      {!!reviewStats.count && (
        <>
          <ReviewStars level={reviewStats.level} />
          <p className="mb-0 ms-10 text-xs-14">
            <FormattedMessage
              id="product_detail.reviews.counter"
              values={{ count: reviewStats.count }}
            />
          </p>
        </>
      )}
      {!!reviewStats.count && withLink && (
        <a className="ms-auto" href="#reviews">
          {messages["product_detail.reviews.show_all"]}
        </a>
      )}
      {!reviewStats.count && (
        <span>{messages["product_detail.reviews.no_reviews"]}</span>
      )}
    </div>
  );
};

export { BuyBackGuarantee, Separator, TooltipHint, ReviewsWidget, ReviewStars };
