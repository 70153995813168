import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";
import { FormTextInput } from "./formControls";
import PropTypes from "prop-types";
import * as PropTypeHelpers from "../PropTypeHelpers.js";

const CustomHeader = ({ title, setOpen }) => (
  <div className="modal-header">
    <h5 className="modal-title">{title}</h5>
    <button
      type="button"
      className="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      onClick={() => setOpen(false)}
    />
  </div>
);

CustomHeader.propTypes = {
    title: PropTypes.string,
    setOpen: PropTypes.func.isRequired
}

const FormFooter = ({
  onConfirm,
  confirmColor,
  confirmLabel,
  confirmType,
  cancelLabel,
  onCancel,
  disableSubmit = false,
}) => {
  const { messages } = useIntl();
  return (
    <>
      {onConfirm && confirmType === "button" && (
        <Button
          color={confirmColor}
          type="button"
          onClick={onConfirm}
          disabled={disableSubmit}
        >
          {confirmLabel || messages["controls.ok"]}
        </Button>
      )}
      {confirmType === "submit" && (
        <Button color={confirmColor} type="submit">
          {confirmLabel || messages["controls.ok"]}
        </Button>
      )}
      <Button color="secondary" type="button" onClick={onCancel}>
        {cancelLabel || messages["controls.cancel"]}
      </Button>
    </>
  );
};

FormFooter.propTypes = {
    onConfirm: PropTypes.func,
    confirmColor: PropTypes.string,
    confirmLabel: PropTypes.string,
    confirmType: PropTypes.oneOf(['button', 'submit']),
    cancelLabel: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    disableSubmit: PropTypes.bool
}

const PopupInterior = ({
  title,
  children,
  onConfirm,
  setOpen,
  cancelLabel,
  confirmColor,
  confirmLabel,
  confirmType,
  disableSubmit,
}) => (
  <>
    {title && <ModalHeader>{title}</ModalHeader>}
    {children && <ModalBody>{children}</ModalBody>}
    <ModalFooter>
      <FormFooter
        onConfirm={onConfirm}
        onCancel={() => setOpen(false)}
        cancelLabel={cancelLabel}
        confirmColor={confirmColor}
        confirmLabel={confirmLabel}
        confirmType={confirmType}
        disableSubmit={disableSubmit}
      />
    </ModalFooter>
  </>
);

PopupInterior.propTypes = {
    title: PropTypes.string,
    children: PropTypeHelpers.children,
    onConfirm: PropTypes.func,
    setOpen: PropTypes.func.isRequired,
    cancelLabel: PropTypes.string,
    confirmColor: PropTypes.string,
    confirmLabel: PropTypes.string,
    confirmType: PropTypes.oneOf(['button', 'submit']),
    disableSubmit: PropTypes.bool
}

const CloseButton = ({ onClick }) => (
  <button type="button" className="btn-close" onClick={onClick}>
    {" "}
  </button>
);

CloseButton.propTypes = {
    onClick: PropTypes.func
}

const BasePopup = ({
  children,
  isOpen,
  setOpen,
  title,
  onConfirm,
  confirmLabel = null,
  confirmColor = "primary",
  cancelLabel = null,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClosed={() => setOpen(false)}
      toggle={() => setOpen(!isOpen)}
    >
      <PopupInterior
        confirmType="button"
        confirmColor={confirmColor}
        confirmLabel={confirmLabel}
        title={title}
        setOpen={setOpen}
        cancelLabel={cancelLabel}
        onConfirm={onConfirm}
      >
        {children}
      </PopupInterior>
    </Modal>
  );
};

BasePopup.propTypes = {
    ...PopupInterior.propTypes,
}

const YesNoPopup = ({
  isOpen,
  setOpen,
  title,
  message,
  confirmLabel,
  confirmColor,
  cancelLabel,
  onConfirm,
}) => (
  <BasePopup
    isOpen={isOpen}
    setOpen={setOpen}
    title={title}
    confirmLabel={confirmLabel}
    confirmColor={confirmColor}
    cancelLabel={cancelLabel}
    onConfirm={onConfirm}
  >
    {message && <p>{message}</p>}
  </BasePopup>
);

YesNoPopup.propTypes = {
    ...PopupInterior.propTypes
}

const MessagePopup = ({ isOpen, setOpen, title, children, confirmLabel }) => {
  const { messages } = useIntl();
  return (
    <Modal
      isOpen={isOpen}
      onClosed={() => setOpen(false)}
      toggle={() => setOpen(!isOpen)}
    >
      <ModalHeader toggle={() => setOpen(!isOpen)}>{title}</ModalHeader>
      {!!children && <ModalBody>{children}</ModalBody>}
      <ModalFooter>
        <Button color="primary" onClick={() => setOpen(false)}>
          {confirmLabel || messages["controls.close"]}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

MessagePopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypeHelpers.children,
    confirmLabel: PropTypes.string
}

const FormPopup = ({
  children,
  isOpen,
  setOpen,
  onSubmit,
  cancelLabel,
  title,
  confirmLabel,
  initialValues,
  onValidate,
  disableSubmit = false,
  size,
}) => (
  <Modal
    isOpen={isOpen}
    onClosed={() => setOpen(false)}
    toggle={() => setOpen(!isOpen)}
    size={size}
  >
    <Formik
      initialValues={initialValues}
      validate={onValidate}
      onSubmit={onSubmit}
    >
      <Form>
        <PopupInterior
          setOpen={setOpen}
          confirmLabel={confirmLabel}
          title={title}
          onConfirm={onSubmit}
          cancelLabel={cancelLabel}
          confirmColor="primary"
          confirmType="submit"
          disableSubmit={disableSubmit}
        >
          {children}
        </PopupInterior>
      </Form>
    </Formik>
  </Modal>
);

FormPopup.propTypes = {
    children: PropTypeHelpers.children,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    confirmLabel: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    onValidate: PropTypes.func,
    disableSubmit: PropTypes.bool,
    size: PropTypes.string
}

const SingleInputPopup = ({
  title,
  message,
  placeholder,
  required,
  onSubmit,
  isOpen,
  setOpen,
  onValidate,
  initialValues,
  confirmLabel,
  maxLength,
}) => (
  <FormPopup
    isOpen={isOpen}
    setOpen={setOpen}
    onSubmit={onSubmit}
    title={title}
    onValidate={onValidate}
    initialValues={initialValues}
    confirmLabel={confirmLabel}
  >
    <Row>
      <Col>
        <FormGroup>
          <FormTextInput
            name="text"
            required={required}
            placeholder={placeholder}
            label={message}
            type="text"
            maxLength={maxLength}
          />
        </FormGroup>
      </Col>
    </Row>
  </FormPopup>
);

SingleInputPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.string,
    confirmLabel: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    onValidate: PropTypes.func,
    message: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number
}

export {
  BasePopup,
  YesNoPopup,
  FormPopup,
  SingleInputPopup,
  CloseButton,
  CustomHeader,
  MessagePopup,
};
