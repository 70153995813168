import {captureException} from "@sentry/react";

const initKlaviyoObject = () => {
  !function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();
}

const reportInternal = (context) => {
  if (!context) {
    return;
  }
  if (window.allowAnalytic() && window.allowMarketing()) {
    initKlaviyoObject();
    const { identification, events } = context;
    if (events && events.length > 0) {
      window.klaviyo.identify(identification).then(() => {
        events.forEach((e) => {
          if (e.operation === 'track') {
            window.klaviyo.push(['track', e.event, e.data])
          }
          else if (e.operation === 'trackViewedItem') {
            window.klaviyo.push(['trackViewedItem', e.data])
          }
          else {
            console.error(`Unknown track operation: "${e.operation}"`)
          }
        })
      });
    }
    else if (identification) {
      window.klaviyo.identify(identification);
    }

  }
}

const reportKlaviyoContextFrom = (data) => {
  return reportInternal(data);
}


const reportKlaviyoContext = () => {
  const e = document.getElementById('klaviyo-context');
  if (e && e.innerText) {
    try {
      const context = JSON.parse(e.innerText);
      reportInternal(context);
    }
    catch(e) {
      captureException(e);
    }
  }
}

export { reportKlaviyoContextFrom, reportKlaviyoContext }

