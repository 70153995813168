import React from "react";
import _ from "lodash";
import { MessagePopup } from "./popups";

/*
const CloseButton = ({ removeError, guid }) => (
  <div className="toast-close">
    <button
      type="button"
      className="btn-none"
      onClick={() => removeError(guid)}
    >
      <span className="icon icon--regular icon--close" />
    </button>
  </div>
);

const ToastError = ({ err, messages, removeError }) => (
  <Toast>
    <ToastHeader className="position-relative">
      {err.title || messages["controls.server_error"]}
      <CloseButton removeError={removeError} guid={err.guid} />
    </ToastHeader>
    <ToastBody className={`toast-body-${err.type}`}>{err.message}</ToastBody>
  </Toast>
);

const ToastStandard = ({ err, removeError }) => {
  let message = "";
  let title = "";
  if (err.title) {
    title = err.title;
    message = err.message;
  } else {
    title = err.message;
  }
  return (
    <Toast>
      <ToastHeader className="position-relative">
        {title}
        <CloseButton removeError={removeError} guid={err.guid} />
      </ToastHeader>
      {message && (
        <ToastBody className={`toast-body-${err.type}`}>{message}</ToastBody>
      )}
    </Toast>
  );
};

const ErrorList = ({ errors, id, removeError }) => {
  const { messages } = useIntl();
  return (
    <div className="global-error-list" id={id}>
      {errors.map((err) => (
        <>
          {err.type === "error" && (
            <ToastError
              key={err.guid}
              err={err}
              messages={messages}
              removeError={removeError}
            />
          )}
          {err.type !== "error" && (
            <ToastStandard key={err.guid} err={err} removeError={removeError} />
          )}
        </>
      ))}
    </div>
  );
};
 */

const ErrorList = ({ errors, removeError }) => {
  const firstMessage = errors.length > 0 ? errors[0] : null;

  React.useEffect(() => {
    if (errors && errors.length === 0) {
      const body = document.getElementsByTagName("body")[0];
      const currentOverflow = _.get(body, "style.overflow", null);
      if (currentOverflow === "hidden") {
        body.style.removeProperty("overflow");
      }
    }
  }, [errors]);

  if (!firstMessage) {
    return null;
  }

  return (
    <MessagePopup
      isOpen={!!firstMessage}
      title={firstMessage.title ? firstMessage.title : firstMessage.message}
      setOpen={(open) => {
        if (!open) {
          removeError(firstMessage.guid);
        }
      }}
    >
      {firstMessage.title && <p>{firstMessage.message}</p>}
    </MessagePopup>
  );
};

export default ErrorList;
