import moment from "moment";
import {useCallback, useEffect, useMemo, useState} from "react";

const zeroPad = (number, reqLength) => {
  let numberStr = number.toString();
  while (numberStr.length < reqLength) {
    numberStr = "0" + numberStr;
  }
  return numberStr;
}

const CountdownApp = ({ date }) => {
  const [renderString, setRenderString] = useState('');
  const deadline = useMemo(() => {
    const parseString = `${date}T00:00:00`
    const m = moment.utc(parseString);
    return m.add(5,'hours');
  }, [date])

  const refreshCounter = useCallback(() => {
    const current = moment()
    if (current < deadline) {
      const deltaMinutes = Math.floor((deadline - current) / 60000);
      const days = Math.floor(deltaMinutes / 1440);
      const hours = Math.floor(deltaMinutes % 1440 / 60);
      const minutes = deltaMinutes % 60;
      if (days > 0) {
        setRenderString(` ${days}:${zeroPad(hours, 2)}:${zeroPad(minutes, 2)} `);
      }
      else {
        setRenderString(` ${hours}:${zeroPad(minutes, 2)} `);
      }
    }
    else {
      setRenderString('');
    }
  }, [deadline])

  useEffect(() => {
    refreshCounter();
    const interval = setInterval(refreshCounter, 1000);
    return () => {
      clearInterval(interval);
    }
  }, [refreshCounter]);

  return renderString;
}

export default CountdownApp;