const messages = {
  "controls.select.placeholder": "Select",
  "controls.buy_back_guarantee": "Buy back guarantee",
  "controls.buy_back_guarantee.hint":
    "We stand behind the quality and fit of our holsters to the extent that we'll buy them back from you in case you're not fully satisfied. ",
  "controls.server_error": "An error occurred",
  "controls.ok": "OK",
  "controls.cancel": "Cancel",
  "controls.close": "Close",
  "controls.submit": "Submit",
  "controls.yes": "Yes",
  "controls.no": "No",
  "controls.jumbo.see_detail": "See detail",
  "controls.months.m1": "January",
  "controls.months.m2": "February",
  "controls.months.m3": "March",
  "controls.months.m4": "April",
  "controls.months.m5": "May",
  "controls.months.m6": "June",
  "controls.months.m7": "July",
  "controls.months.m8": "August",
  "controls.months.m9": "September",
  "controls.months.m10": "October",
  "controls.months.m11": "November",
  "controls.months.m12": "December",
  "controls.forms.field_required": "This field is required",
  "controls.forms.email_invalid": "Invalid email address",
  "controls.forms.zip_invalid":
    "ZIP code can only contain letters and numbers, up to 9 characters",
  "controls.color_selector.stock": "stock",
  "controls.filter.show_all": "Show All",

  "product_detail.add_to_cart": "Add to cart",
  "product_detail.gallery.video": "Video", // "Video ({length})",
  "product_detail.gallery.product_gallery": "Product gallery",
  "product_detail.gallery.customer_photos": "Customer photos",
  "product_detail.gallery.carry_gallery": "Carry gallery",
  "product_detail.reviews.counter":
    "{count, plural , one {# review} other {# reviews}}",
  "product_detail.reviews.show_all": "Show all",
  "product_detail.reviews.no_reviews": "No reviews",
  "product_detail.reviews.vote_error_not_auth.message": "Please login to vote",
  "product_detail.reviews.vote_error_not_auth.title": "Not allowed",
  "product_detail.reviews.upload_images_error_not_auth":
    "In order to upload images, you need to log in",
  "product_detail.video.play1": "Play",
  "product_detail.video.play2": "Video",
  "product_detail.ordered_count": "Ordered <b>{count}x</b> this week",
  "product_detail.options.gun_manufacturer": "Gun",
  "product_detail.options.gun_model": "Model",
  "product_detail.options.add_monogram": "Add monogram embossing",
  "product_detail.options.monogram_placeholder": "Monogram (max 3 letters)",
  "product_detail.options.monogram_hint":
    "Get your holster embossed with up to 3 letters or numbers to make it even more custom and personal.",
  "product_detail.shipping_info.ships_on_label": "Ships on",
  "product_detail.shipping_info.delivery_on_label": "Delivery",
  "product_detail.shipping_info.out_of_stock": "Out of stock",
  "product_detail.shipping_info.shipping_hint":
    "Estimated delivery by UPS Express",
  "product_detail.shipping_info.missing_specification":
    "Please specify all parameters",
  "product_detail.added_popup.error.item_not_found":
    "Cart item no longer exists",
  "product_detail.added_popup.title": "Added to shopping cart",
  "product_detail.added_popup.continue_shopping": "Continue Shopping",
  "product_detail.added_popup.view_cart": "View Cart",
  "product_detail.added_popup.free_shipping.counter_remaining":
    "Shop for another <orange>{amount}</orange> to get <b>Free Shipping</b>",
  "product_detail.added_popup.free_shipping.counter_fulfilled":
    "<b>Free Shipping</b> available for this order",
  "product_detail.additional_gun_specification": "Additional gun specification",
  "product_detail.cascade_specification.caliber": "Caliber",
  "product_detail.cascade_specification.capacity": "Capacity",
  "product_detail.cascade_specification.extra_note":
    "Please specify caliber/capacity in order note",
  "product_detail.other_variants": "Other variants:",

  "reviews.title": "{productName} Customer Reviews",
  "reviews.filter.newest": "Newest",
  "reviews.filter.most_helpful": "Most helpful",
  "reviews.filter.photos_only": "Reviews with photos only",
  "reviews.filter.sort_by": "Sort by",
  "reviews.add_review": "Add a Review",
  "reviews.filter.rating": "Rating",
  "reviews.filter.country": "Country",
  "reviews.filter.state": "State",
  "reviews.filter.other_countries": "Other countries",
  "reviews.errors.could_not_fetch_message": "Could not load reviews",
  "reviews.errors.could_not_fetch_title": "Reviews error",
  "reviews.helpful": "Was this Review helpful?",
  "reviews.popup.title": "Write a Review",
  "reviews.popup.field.rating": "Your rating",
  "reviews.popup.field.name": "Your name",
  "reviews.popup.field.content": "Your opinion",
  "reviews.popup.field.photos": "Add photos to your review",
  "reviews.popup.field.photos.button": "Select photos",
  "reviews.popup.submit": "Submit your review",
  "reviews.popup.uploading":
    "Uploading your review. This may take a few moments",
  "reviews.popup.errors.name_too_long": "Name is too long",
  "cart.title": "Shopping Cart",
  "cart.empty": "Your shopping cart is empty",
  "cart.back_to_shop": "Back to shop",
  "cart.table.product": "Product",
  "cart.table.quantity": "Quantity",
  "cart.table.price": "Price",
  "cart.item.reservation": "Reserved for: {time}",
  "cart.item.edit": "Edit",
  "cart.item.delete": "Delete",
  "cart.table.warranty": "Lifetime Warranty",
  "cart.table.customer_note": "Customer note",
  "cart.table.warranty_free": "Free",
  "cart.note.title": "Notes",
  "cart.note.buttonAdd": "Add a Note",
  "cart.note.buttonEdit": "Change Note",
  "cart.coupon.title": "Coupon code",
  "cart.coupon.button": "I have a Code",
  "cart.note.label": "Enter additional order specification",
  "cart.coupons.popup.title": "Coupons",
  "cart.coupons.popup.add_label": "Add coupon",
  "cart.coupons.popup.add_placeholder": "Enter coupon code",
  "cart.coupons.popup.submit": "Submit",
  "cart.coupons.applied": "Discount applied",
  "cart.coupons.removed": "Discount removed",
  "cart.add_optional_extras": "Add optional extras",
  "cart.summary.title": "Summary",
  "cart.summary.shipping.hint": "Estimated delivery by UPS Express",
  "cart.summary.if_ordered_today": "(if ordered today)",
  "cart.summary.ships_on": "Ships on",
  "cart.summary.delivers_on": "Delivery",
  "cart.summary.subtotal": "Subtotal",
  "cart.summary.shipping": "Shipping",
  "cart.summary.tax": "Tax and fees",
  "cart.summary.total": "Subtotal",
  "cart.summary.discount": "Discount",
  "cart.checkout_button": "Checkout",
  "cart.buyback_guarantee": "We'll buy it back guarantee",
  "cart.item.remove.title": "Remove Item?",
  "cart.detail_popup.title": "Change cart item",
  "cart.edit_monogram_popup.title": "Monogram",
  "cart.edit_monogram_popup.confirm": "Save",
  "cart.edit_monogram_popup.cancel": "Back",
  "cart.edit_monogram_popup.add": "Add Monogram Embossing ({price})",
  "cart.edit_monogram_popup.update": "Update or Remove Monogram",

  "checkout.title": "Checkout",
  "checkout.login.button": "Have an account? Sign in",
  "checkout.login.form.email": "Email",
  "checkout.login.form.password": "Password",
  "checkout.login.form.button_submit": "Sign in",
  "checkout.login.form.button_cancel": "Cancel",
  "checkout.login_form.title": "Sign In",
  "checkout.login_form.email": "Your email",
  "checkout.login_form.password": "Password",
  "checkout.login_form.submit": "Sign in",
  "checkout.login_form.lost_password": "Lost your password?",
  "checkout.login_form.recover_password": "Recover password",
  "checkout.form.email": "Your email",
  "checkout.form.email.placeholder": "your@email.com",
  "checkout.delivery_address.title": "Delivery address",
  "checkout.billing_address.title": "Billing address",
  "checkout.form.first_name": "First name",
  "checkout.form.last_name": "Last name",
  "checkout.form.address1": "Address",
  "checkout.form.address2": "Apt/Suite",
  "checkout.form.address1.hint": "Max 35 characters",
  "checkout.form.address2.hint": "Max 35 characters",
  "checkout.form.city": "City",
  "checkout.form.zip": "ZIP",
  "checkout.form.country_iso2": "Country",
  "checkout.form.region_code": "Region",
  "checkout.form.phone": "Phone",
  "checkout.form.phone.placeholder": "+1 ...",
  "checkout.form.phone.hint":
    "Your phone no. will be used only for delivery purposes. Please use format with country code.",
  "checkout.no_billing_address": "Use delivery as billing address",
  "checkout.delivery_options.title": "Delivery options",
  "checkout.payment.title": "Payment",
  "checkout.payment.tabs.credit_card.title": "Credit Card",
  "checkout.payment.tabs.paypal.title": "PayPal",
  "checkout.payment.cc_form.number": "Card number",
  "checkout.payment.cc_form.name": "Name on card",
  "checkout.payment.cc_form.exp_month": "Expiry month",
  "checkout.payment.cc_form.exp_year": "Expiry year",
  "checkout.payment.cc_form.cvv": "CVV",
  "checkout.payment.cc_form.cvv_hint":
    "A 3 or 4 digit code on your credit card.",
  "checkout.place_order": "Place order",
  "checkout.create_order": "Create order & Pay With PayPal",
  "checkout.summary.title": "Summary",
  "checkout.summary.table.product": "Product",
  "checkout.summary.table.quantity": "Quantity",
  "checkout.summary.table.price": "Price",
  "checkout.summary.delivery": "Delivery",
  "checkout.summary.shipping.hint":
    "The estimated delivery date depends on stock availability and/or production time of your new holster. Making changes to your order or paying for it later might postpone the delivery time.",
  "checkout.remote_area_detected":
    "Rural / remote areas may not be covered by delivery companies and the delivery will be forwarded to USPS with longer delivery time.",
  "checkout.shipping_recommendation": "Our Customers' Top Choice",
  "checkout.shipping_method.pobox_not_allowed":
    "P.O. Box is not allowed for selected shipping method",

  "profile.crumb.home": "Home",
  "profile.crumb.club": "Holsters Club™",
  "profile.crumb.profile": "My Profile",
  "profile.banner.title": "HOLSTERS CLUB™",
  "profile.menu.button": "Menu",
  "profile.menu.title": "Menu",
  "profile.menu.guns": "My Guns",
  "profile.menu.orders": "My Orders",
  "profile.menu.discounts": "My Discounts",
  "profile.menu.profile": "My Profile",
  "profile.menu.password": "Change Password",
  "profile.menu.newsletter": "Newsletter Preferences",
  "profile.menu.logout": "Log out",
  "profile.meta.title.guns": "My Guns - Craft Holsters®",
  "profile.meta.title.orders": "My Orders - Craft Holsters®",
  "profile.meta.title.discounts": "My Discounts - Craft Holsters®",
  "profile.meta.title.profile": "My Profile - Craft Holsters®",
  "profile.meta.title.password": "Change Password - Craft Holsters®",
  "profile.meta.title.newsletter": "Newsletter - Craft Holsters®",
  "profile.discounts.title": "My Discounts",
  "profile.guns.title": "My Guns",
  "profile.guns.add": "Add new Gun",
  "profile.guns.add_popup.title": "Add new Gun",
  "profile.guns.add_popup.manufacturer": "Gun manufacturer",
  "profile.guns.add_popup.model": "Gun model",
  "profile.guns.remove_popup.title": "Remove gun",
  "profile.guns.remove_popup.message":
    "Are you sure you want to remove this gun from your list?",
  "profile.guns.link": "Find Holsters{br} for this gun",
  "profile.guns.no_guns": "No guns were specified",
  "profile.user_info.title": "My Profile",
  "profile.user_info.personal.title": "Personal Details",
  "profile.user_info.personal.first_name": "First Name",
  "profile.user_info.personal.last_name": "Last Name",
  "profile.user_info.personal.email": "Email",
  "profile.user_info.personal.phone": "Phone",
  "profile.user_info.personal.submit": "Save changes",
  "profile.user_info.address.title": "Invoicing address",
  "profile.user_info.address.address1": "Address line 1",
  "profile.user_info.address.address2": "Address line 2",
  "profile.user_info.address.city": "City",
  "profile.user_info.address.zip": "ZIP",
  "profile.user_info.address.country": "Country",
  "profile.user_info.address.region": "State/Region",
  "profile.user_info.address.submit": "Save changes",
  "profile.user_info.address_saved.text": "Address changes saved",
  "profile.user_info.address_saved.title": "Success",
  "profile.user_info.personal_saved.text": "Personal info saved",
  "profile.user_info.personal_saved.title": "Success",
  "profile.password.title": "Change Password",
  "profile.password.old": "Old password",
  "profile.password.new": "New password",
  "profile.password.confirm": "Confirm password",
  "profile.password.submit": "Change my Password",
  "profile.password.success.message": "Your password was changed successfully",
  "profile.password.success.title": "Password changed",
  "profile.newsletter.title": "Newsletter Preferences",
  "profile.newsletter.toggle": "I wish to receive Newsletter",
  "profile.newsletter.resend": "Re-Send Latest Newsletter",
  "profile.newsletter.enabled": "Newsletter enabled",
  "profile.newsletter.disabled": "Newsletter disabled",
  "profile.newsletter.success.title": "Success",
  "profile.newsletter.was_resent": "Latest newsletter resent",
  "profile.order_list.title": "My Orders",
  "profile.order_list.summary.active": "Active Orders",
  "profile.order_list.summary.count": "Total Orders",
  "profile.order_list.summary.total": "Total Value",
  "profile.order_list.order.created_at": "Created {createdAt} ({ago})",

  "holsterfinder.armory.select.placeholder": "Select Manufacturer",
  "holsterfinder.model.select.placeholder": "Select Gun Model",

  "sidebar.popup.confirm": "Apply and close",
  "sidebar.popup.view_results": "View {count} results",
  "sidebar.popup.title": "Filter",
  "cookie_consent.popup_title": "We use cookies",
  "cookie_consent.title": "This website uses cookies",
  "cookie_consent.text":
    "In order to enhance your experience and show you more relevant information, we use cookies and similar technologies, both ours and third-party. For more information about the cookies we use visit our <privacyPolicyLink>Privacy Policy</privacyPolicyLink>.",
  "cookie_consent.essential.title": "Necessary",
  "cookie_consent.essential.text":
    "Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.",
  "cookie_consent.analytic.title": "Statistics",
  "cookie_consent.analytic.text":
    "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.",
  "cookie_consent.marketing.title": "Marketing",
  "cookie_consent.marketing.text":
    "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.",
  "cookie_consent.button.accept_all": "Allow all",
  "cookie_consent.button.accept_choices": "Allow selection",
  "cookie_consent.button.deny_all": "Deny",
  "cookie_consent.button.customize": "Customize",
};

export default messages;
