import React from "react";
import { Field, useFormikContext } from "formik";
import {
  AssignRating,
  JumboRadio,
  Select,
  SimpleCheckbox,
  StandardLabel,
  TextInput,
  TextArea,
} from "./controls";

const FieldErrors = ({ name, errors, status, touched }) => {
  if ((errors[name] && touched[name]) || status[name]) {
    return <div className="invalid-feedback d-block">{errors[name]}</div>;
  }
  return null;
};

const FormTextInput = ({
  label,
  id,
  name,
  type,
  placeholder,
  required,
  readonly,
  maxLength,
  hint,
  onBlur,
  className = "form-control",
  transformValue = null,
}) => {
  const {
    isSubmitting,
    errors,
    status = {},
    setFieldValue,
    touched,
  } = useFormikContext();

  React.useEffect(() => {
    if (isSubmitting && errors[name] && touched[name]) {
      const e = document.getElementById(id || `id_${name}`);
      if (e) {
        e.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [isSubmitting, errors, touched, id, name]);

  return (
    <Field name={name}>
      {({ field }) => (
        <>
          <TextInput
            type={type}
            label={label}
            placeholder={placeholder}
            id={id || `id_${name}`}
            name={name}
            required={required}
            value={field.value || ""}
            onChange={(e) => {
              let newValue = e.target.value;
              if (transformValue) {
                newValue = transformValue(newValue);
              }
              setFieldValue(name, newValue);
            }}
            readonly={readonly}
            disabled={isSubmitting}
            maxLength={maxLength}
            hint={hint}
            onBlur={onBlur}
            className={className}
            isInvalid={
              touched &&
              touched[name] &&
              errors &&
              errors[name] &&
              errors[name].length
            }
          />
          <FieldErrors
            errors={errors}
            name={name}
            status={status}
            touched={touched}
          />
        </>
      )}
    </Field>
  );
};

const FormSelect = ({
  label,
  id,
  name,
  placeholder,
  required,
  readonly,
  options,
  onChange,
  onBlur,
}) => {
  const {
    isSubmitting,
    errors,
    status = {},
    setFieldValue,
    touched,
  } = useFormikContext();

  React.useEffect(() => {
    if (isSubmitting && errors[name] && touched[name]) {
      const e = document.getElementById(id || `id_${name}`);
      if (e) {
        e.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [isSubmitting, errors, touched, id, name]);
  return (
    <Field name={name}>
      {({ field }) => (
        <>
          {label && <StandardLabel id={id || `id_${name}`} label={label} />}
          <Select
            options={options}
            onChange={(fieldName, newValue) => {
              setFieldValue(fieldName, newValue);
              if (onChange) {
                onChange(fieldName, newValue);
              }
            }}
            value={field.value}
            placeholder={placeholder}
            name={name}
            id={id || `id_${name}`}
            required={required}
            readonly={readonly}
            disabled={isSubmitting}
            onBlur={onBlur}
            isInvalid={
              touched &&
              touched[name] &&
              errors &&
              errors[name] &&
              errors[name].length
            }
          />
          <FieldErrors
            errors={errors}
            name={name}
            status={status}
            touched={touched}
          />
        </>
      )}
    </Field>
  );
};

const FormCheckbox = ({ label, id, name, size }) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { isSubmitting, errors, status = {}, setFieldValue, touched },
      }) => (
        <>
          <SimpleCheckbox
            label={label}
            id={id || `id_${name}`}
            name={name}
            size={size}
            value={field.value}
            onChange={(fieldName, value) => {
              setFieldValue(fieldName, value);
            }}
            disabled={isSubmitting}
          />
          <FieldErrors
            errors={errors}
            name={name}
            status={status}
            touched={touched}
          />
        </>
      )}
    </Field>
  );
};

const FormJumboRadio = ({ children, id, name, itemValue, disabled }) => (
  <Field name={name}>
    {({ field, form: { setFieldValue } }) => (
      <JumboRadio
        onChange={(fieldName, selectedValue) => {
          if (selectedValue === itemValue) {
            setFieldValue(fieldName, selectedValue);
          }
        }}
        checked={field.value === itemValue}
        name={name}
        id={id}
        itemValue={itemValue}
        disabled={disabled}
      >
        {children}
      </JumboRadio>
    )}
  </Field>
);

const FormAssignRating = ({ name }) => (
  <Field name={name}>
    {({ field, form: { setFieldValue, errors, status = {}, touched } }) => (
      <>
        <AssignRating
          currentValue={field.value}
          onValueChanged={(newValue) => {
            setFieldValue(name, newValue);
          }}
        />
        <FieldErrors
          errors={errors}
          name={name}
          status={status}
          touched={touched}
        />
      </>
    )}
  </Field>
);

const FormTextArea = ({ id, name, placeholder, rows = 5 }) => (
  <Field name={name}>
    {({ field, form: { setFieldValue, errors, status = {}, touched } }) => (
      <>
        <TextArea
          value={field.value}
          rows={rows}
          name={name}
          id={id || `id_${name}`}
          placeholder={placeholder}
          onChange={(e) => setFieldValue(name, e.target.value)}
        />
        <FieldErrors
          errors={errors}
          name={name}
          status={status}
          touched={touched}
        />
      </>
    )}
  </Field>
);

export {
  FormTextInput,
  FormSelect,
  FormCheckbox,
  FormJumboRadio,
  FormAssignRating,
  FormTextArea,
  FieldErrors,
};
