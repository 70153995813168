import React from "react";

const ErrorHandlingContext = React.createContext({
  errors: [],
  reportError: () => {},
  safeRequest: () => {},
  directApiRequest: () => {},
  reportMessage: () => {},
  extractFormErrors: () => {},
});

export default ErrorHandlingContext;
