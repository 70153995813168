import React from "react";

const ConfigContext = React.createContext(null);

const ConfigWrapper = ({ config, children }) => {
  const [configData, setConfigData] = React.useState(null);

  React.useEffect(() => {
    const e = document.getElementById(config);
    if (e) {
      const newConfigData = JSON.parse(e.textContent);
      setConfigData(newConfigData);
    }
  }, [config]);

  if (!configData) {
    return null;
  }

  return (
    <ConfigContext.Provider value={configData}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext };
export default ConfigWrapper;
