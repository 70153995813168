import { Modal } from "reactstrap";

const FloatingSpinner = ({ loading = true }) => {
  if (loading) {
    return (
      <Modal size="sm" isOpen className="forced-center">
        <div className="floating-spinner-container">
          <div className="spinner-border" />
        </div>
      </Modal>
    );
  }
  return null;
};

export default FloatingSpinner;
